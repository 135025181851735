<template>
	<PopupTwoCol name="bsm"
	text="<h3>Bâtiment sécurité maritime</h3><br/><p>Les équipes supervisent et gèrent les 6 appontements qui permettent à la raffinerie de recevoir les navires de pétrole brut et d'expédier les produits finis. La maitrise de l'anglais est une compétence importante pour pouvoir échanger avec les transporteurs maritimes venant du monde entier. Le trafic représente 50 % du Grand Port Maritime Nantes St Nazaire.</p><br/><p>Les opérateurs assurent également la sécurité du site, notamment grâce à l'une des cinq salles de contrôle de la raffinerie qui se trouve au sein de ce bâtiment.</p>">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/BSM_salle_de_control_w0tafy_c_scale_w_350.jpg 350w,
		img/popup/BSM_salle_de_control_w0tafy_c_scale_w_706.jpg 706w,
		img/popup/BSM_salle_de_control_w0tafy_c_scale_w_970.jpg 970w"
		data-src="img/popup/BSM_salle_de_control_w0tafy_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
	<picture slot="thumbnail__2">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/BSM_qqwzpu_c_scale_w_350.jpg 350w,
		img/popup/BSM_qqwzpu_c_scale_w_706.jpg 706w,
		img/popup/BSM_qqwzpu_c_scale_w_970.jpg 970w"
		data-src="img/popup/BSM_qqwzpu_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</PopupTwoCol>
</template>

<script>
	export default {
		components: {
			PopupTwoCol: () => import('@/components/PopupTwoCol.vue'),
		},
	}
</script>
